import { useVehicleModelStore } from "@/stores/useVehicleModelStore";
import useLogger from "@/composables/useLogger";

export default defineNuxtRouteMiddleware((to, from) => {
  const logger = useLogger("resetQuote");
  if (process.server) {
    logger.info("Running Serverside - Skipping");
    return;
  }

  const store = useVehicleModelStore();
  store.$reset();
  logger.info("Complete Resetting quote on nav to root DDD");
});
